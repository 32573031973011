// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-news-template-js": () => import("../src/templates/newsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-tournament-template-js": () => import("../src/templates/tournamentTemplate.js" /* webpackChunkName: "component---src-templates-tournament-template-js" */),
  "component---src-templates-player-template-js": () => import("../src/templates/playerTemplate.js" /* webpackChunkName: "component---src-templates-player-template-js" */),
  "component---src-templates-game-template-js": () => import("../src/templates/gameTemplate.js" /* webpackChunkName: "component---src-templates-game-template-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-facts-js": () => import("../src/pages/facts.js" /* webpackChunkName: "component---src-pages-facts-js" */),
  "component---src-pages-gallery-js": () => import("../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-rules-js": () => import("../src/pages/rules.js" /* webpackChunkName: "component---src-pages-rules-js" */),
  "component---src-pages-standings-js": () => import("../src/pages/standings.js" /* webpackChunkName: "component---src-pages-standings-js" */),
  "component---src-pages-stats-js": () => import("../src/pages/stats.js" /* webpackChunkName: "component---src-pages-stats-js" */),
  "component---src-pages-tournaments-js": () => import("../src/pages/tournaments.js" /* webpackChunkName: "component---src-pages-tournaments-js" */)
}

